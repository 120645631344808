import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    background: `url(${require('../../data/images/hero.png')}) no-repeat center center`,
    backgroundSize: 'cover',
    width: '100vw',
    height: '500px',
    marginTop: -1,
  },
  header: {
    position: 'absolute',
    right: 0,
    left: 0,
    margin: 'auto',
    top: '40%',
  },
  heroGrid: {
    maxWidth: '100%',
    margin: 'auto',
  },
  searchGrid: {
    zIndex: 3,
  },
  textField: {
    width: '100%',
  },
}));

const Hero = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <section className={classes.root}>
      <div>
        <SectionHeader
          title={t('aboutHeroTitle')}
          subtitle={t('aboutHeroSubtitle')}
          fadeUp
          titleVariant="h4"
          subtitleVariant="body1"
          titleProps={{
            style: { color: theme.palette.primary.contrastText },
          }}
          subtitleProps={{
            style: {
              color: theme.palette.primary.contrastText,
              maxWidth: '80%',
              margin: 'auto',
            },
          }}
          className={classes.header}
        />
      </div>
    </section>
  );
};

export default Hero;
