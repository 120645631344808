import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FloatingIcons, Section } from 'components/organisms';
import { Beliefs, Contact, Hero } from './components';
import { DefaultSEO } from 'components/DefaultSEO';

const useStyles = makeStyles(theme => ({
  sectionAlternateForm: {
    [theme.breakpoints.up('md')]: {
      sectionAlternateForm: {
        maxWidth: '75%',
        margin: 'auto',
      },
    },
  },
}));

const About = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div>
      <DefaultSEO
        title="THE CENTER SPACE | Our Beliefs"
        description="Take care of your people and they will take care of you. Invest in the space where your team can do their best work."
      />
      <Hero />
      <FloatingIcons />
      <Beliefs />
      <div className={classes.sectionAlternateForm}>
        <Section>
          <Contact />
        </Section>
      </div>
    </div>
  );
};

export default About;
