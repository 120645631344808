import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Image } from 'components/atoms';
import { useMediaQuery, Grid, Typography } from '@material-ui/core';

import { Section } from 'components/organisms';
import { SectionHeader } from 'components/molecules';
import quotesIcon from '../../data/images/quotesIcon.svg';
import logo from '../../data/images/logo.svg';
import bg from '../../data/images/bg.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles(theme => ({
  beliefs: {
    background: `url(${bg}) repeat`,
    backgroundSize: 'cover',
    maxHeight: '392px',
    marginBottom: '3%',
  },
  beliefsWrapper: {
    position: 'relative',
  },
  beliefsGrid: {
    maxWidth: '90%',
    margin: 'auto',
    marginTop: '-70px',
  },
  quotesIcon: {
    position: 'absolute',
    height: 81,
    width: 96,
    left: 16,
    top: 122,
    [theme.breakpoints.up('md')]: {
      height: 125,
      width: 150,
      left: '25%',
      top: '15%',
    },
  },
}));

const Beliefs = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <section className={classes.beliefs}>
      <Section className={classes.beliefsWrapper}>
        <>
          <Image
            src={quotesIcon}
            alt="Background Image"
            className={classes.quotesIcon}
          />
          <Grid
            container
            spacing={isMd ? 5 : 5}
            className={classes.beliefsGrid}
          >
            <Grid item xs={12}>
              <div style={{ textAlign: 'center' }}>
                <Image
                  src={logo}
                  alt="Center Space Logo"
                  data-aos="fade-up"
                  style={{ width: '98px' }}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <SectionHeader
                title={t('beliefsTitle')}
                subtitle={
                  <>
                    {t('beliefsSubtitle1')}
                    <br />
                    {t('beliefsSubtitle2')}
                  </>
                }
                fadeUp
                titleVariant="h3"
                subtitleVariant="body1"
                subtitleProps={{
                  style: {
                    maxWidth: '100%',
                    margin: 'auto',
                    color: theme.palette.primary.main,
                    [theme.breakpoints.up('md')]: {
                      maxWidth: '60%',
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </>
      </Section>
    </section>
  );
};

export default Beliefs;
